.container {
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

@media (min-width: 1024px) {
    .container {
        width: 40%;
    }
}

.imageContainer {
    display: flex;
    gap: 1rem;
}

.imageWrapper {
    width: 50%;
}

.textareaContainer {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.textarea {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #d1d5db;
    border-radius: 0.5rem;
    resize: none;
    height: 6rem;
}

.generateButton {
    width: 100%;
    background-color: #f3f4f6;
    color: #1f2937;
    font-weight: 600;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s;
}

.generateButton:hover {
    background-color: #c9cbcf;
}

.videoButton {
    width: 100%;
    background-color: #1a831f;
    color: white;
    font-weight: 600;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s;
}

.videoButton:hover {
    background-color: #116017;
}