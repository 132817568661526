.container {
    width: 50%;
    aspect-ratio: 1;
    background-color: #f3f4f6;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    border: 2px dashed #d1d5db;
}

.placeholder {
    color: #6b7280;
    padding: 5px;
    width: 300px;
    /*height: 90%;*/
    align-content: center;
    text-align: center;
}

.image {
    width: 90%;
    height: 90%;
    object-fit: cover;
    align-content: center;
    border-radius: 0.5rem;
}